import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { changeDateFormat } from "../utils/formatDate";
import { useMutation } from "@apollo/client";
import { CREATE_EARNING, GET_USER_EARNINGS } from "../utils/graphqlQueries";
import { Header } from "semantic-ui-react";

import "./CreateSpending.css";

const CreateEarning = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, getValues } = useForm();
  const [createEarning, { loading, error }] = useMutation(CREATE_EARNING, {
    refetchQueries: [GET_USER_EARNINGS],
  });

  const onSubmit = (formData) => {
    createEarning({
      variables: {
        earningInput: { ...formData, date: changeDateFormat(formData.date) },
      },
    }).then(navigate(-1));
  };

  if (error) return <h1>Error: {error.message}</h1>;
  if (loading) return <h1>Loading...</h1>;

  return (
    <>
      <Header as="h3" dividing>
        Kreiraj Promet
      </Header>

      <form className="spending-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="earningType">Tip:</label>
          <select {...register("earningType")} required>
            <option value="Bijelo">Bijelo</option>
            <option value="Crno">Crno</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="date">Datum:</label>
          <input type="date" {...register("date")} required />
        </div>

        <div className="form-group">
          <label htmlFor="description">Opis:</label>
          <input type="text" {...register("description")} required />
        </div>

        <div className="form-group">
          <label htmlFor="cash_total">Keš:</label>
          <input
            type="number"
            step="any"
            {...register("cash_total", {
              valueAsNumber: true,
              onChange: (e) => {
                const earningType = getValues("earningType");
                if (earningType === "Bijelo") {
                  const cardTotal = getValues("card_total");
                  const totalSum = Number(e.target.value) + Number(cardTotal);
                  const pdv = totalSum * 0.2;
                  setValue("tax_total", pdv.toFixed(2));
                } else {
                  setValue("tax_total", 0);
                  setValue("card_total", 0);
                }
              },
            })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="card_total">Kartica:</label>
          <input
            type="number"
            step="any"
            {...register("card_total", {
              valueAsNumber: true,
              onChange: (e) => {
                const earningType = getValues("earningType");
                if (earningType === "Bijelo") {
                  const cashTotal = getValues("cash_total");
                  const totalSum = Number(e.target.value) + Number(cashTotal);
                  const pdv = totalSum * 0.2;
                  setValue("tax_total", pdv.toFixed(2));
                } else {
                  setValue("tax_total", 0);
                }
              },
            })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="tax_total">Ukupan PDV:</label>
          <input
            type="number"
            step="any"
            {...register("tax_total", { valueAsNumber: true })}
            required
          />
        </div>

        <div className="button-div">
          <button type="submit">Spremi</button>
        </div>
      </form>
    </>
  );
};

export default CreateEarning;
